.custom-nav {
    background-color: rgba($color: #fff, $alpha: 1);
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0%;
    left: 0%;
    display: flex;
    z-index: 100;
    overflow: hidden;
    align-items: flex-end;
    padding: 20px;
    @include sm {
        padding-top: 80px;
        align-items: center;
    }
    .nav {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        @include sm {
            display: none;
        }

        .items {
            display: flex;
            @include sm {
                align-items: center;
                justify-content: space-evenly;
            }
            .item {
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $white;
                color: $black;
                margin: 5px;
                font-family: "Open Sans", sans-serif;
                font-weight: 600;
                text-transform: uppercase;
                a {
                    color: $black;
                    margin: 5px;
                    font-family: "Open Sans", sans-serif;
                    font-size: 13px;
                    font-weight: 600;
                    h5 {
                        font-weight: 400;
                        font-size: 13px;
                        text-transform: uppercase;
                    }
                }
            }
            .item-empty {
                background-color: transparent;
            }
            .item-blue {
                background-color: $blue;
                color: $white;
                a {
                    color: $black;
                }
            }
            img {
                width: 100%;
                height: auto;
                max-height: 200px;
            }
        }
    }

    .toggle-close {
        cursor: pointer;
        position: absolute;
        top: 50px;
        right: 140px;
        width: 50px;
        height: 50px;
        background-color: $blue;
        display: flex;
        justify-content: center;
        align-items: center;
        @include sm {
            right: 25px;
        }
    }

    .nav-mobile {
        @extend .nav;
        display: none;
        @include sm {
            display: block;
        }
        .item {
            width: 100px;
            height: 100px;
            font-size: 13px;
            text-align: center;
            img {
                width: 100% !important;
                min-width: 50px;
                max-height: 100%;
            }
        }
        .items-social {
            .item {
                width: 73px !important;
                height: 73px !important;
            }
        }
    }
}

.mobile-toggle {
    cursor: pointer !important;
    display: none;
    @include sm {
        display: block;
    }
}

nav {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100000;
    transition: 0.3s all;

    &:hover {
        // background-color: #000;
    }
    .container-fluid {
        line-height: 70px !important;
    }
}

.nav-items-wrapper {
    position: relative;
    align-items: center;
    justify-content: center;
    display: flex;
    h5 {
        font-family: "Oswald", sans-serif;
        margin: 0;
        font-size: 11px !important;
        color: #000;
        text-transform: uppercase;
        @include xl {
            font-size: 13px !important;
        }
    }
    @include sm {
        display: none;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
        display: block;
    }
    80% {
        opacity: 0;
        display: block;
    }
    100% {
        opacity: 0;
        display: none;
    }
}

.hide {
    display: none;
    -webkit-animation: fadeOut 1s both;
    animation: fadeOut 1s both;
}

.hide-nav {
    display: none;
}
#home .hide-nav {
    display: flex;
}

.active {
    h5 {
        color: $blue;
        // font-weight: 700 !important;
        font-family: "Oswald", sans-serif;
    }
}
.inactive {
    h5 {
        font-family: "Oswald", sans-serif;
        @extend .active;
        color: #000 !important;
        font-weight: 400 !important;
    }
}

.nav-dropdown-wrapper {
    position: relative;
    .nav-dropdown-button {
        font-family: "Oswald", sans-serif;
        background-color: transparent;
        color: $black !important;
        border: none;
        padding: 0;
        font-size: 11px;
        text-transform: uppercase;
        @include xl {
            font-size: 13px;
        }
    }
    .nav-dropdown-menu {
        position: absolute;
        top: 90%;
        left: 0%;
        z-index: 10000000000000;
        background-color: rgba($color: rgb(119, 119, 119), $alpha: 0.8);
        padding: 5px;
        // width: 150px;
        height: auto;

        h5 {
            font-family: "Oswald", sans-serif;
            margin: 10px;
            color: $white !important;
        }
        .inactive {
            color: $white !important;
        }
    }
    .nav-dropdown-menu-show {
        display: block;
    }
    .nav-dropdown-menu-hide {
        display: none;
    }
}
.navOpacity {
    background: $white;
}

.nav-item {
    margin-left: 2.3rem;
    .icon {
        img {
            margin-top: -10px;
            width: 15px;
            height: 15px;
        }
    }
}
