.timeline-wrapper {
    padding-top: 50px;
    @include xl {
        padding-top: 80px;
    }
    .vertical-timeline.vertical-timeline--two-columns:before {
        background-color: $black;
    }
    .vertical-timeline-element--work .vertical-timeline-element {
        box-shadow: none !important;
        outline: none;
    }
    .vertical-timeline-element-content {
        box-shadow: none !important;
    }
}

#awards {
    .vertical-timeline-element-date {
        color: #000;
    }
}
