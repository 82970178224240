#single {
    .clip {
        width: 100%;
        height: auto;
        // clip-path: polygon(46.2% 64.5%, 67.1% 0%, 100% 0%, 100% 64.8%, 46.2% 64.5%, 63.2% 100%, 0% 100%, 0% 65%, 46.2% 64.5%);
        clip-path: polygon(35% 0, 100% 0, 100% 100%, 0 100%);
    }
    .clip2 {
        width: 100%;
        height: auto;
        max-height: 200px;
        clip-path: polygon(0 0, 70% 0, 100% 100%, 0 100%);
    }
    .clip3 {
        width: 100%;
        height: auto;
    }
    .project-slide {
        margin-top: 50px;
        p {
            font-size: 20px;
        }
        i {
            color: $blue;
        }
        .title {
            font-size: 50px;
        }
        .sub-title {
            margin-top: 20px;
            font-size: 30px;
        }
        .publish {
            margin-top: 20px;
            display: flex;
            font-size: 20px;
            .date {
            }
            .place {
                margin-left: 20px;
            }
        }
        .des {
            font-size: 20px;
            margin-top: 20px;
            @include sm {
                font-size: 16px;
            }
        }

        .topic {
            font-weight: bold;
            font-size: 20px;
            @include sm {
                font-size: 16px;
            }
        }
    }

    .slide-navigation {
        // margin-top: -20px;
        display: flex;
        justify-content: end;
        align-items: center;
        @include sm {
            justify-content: center;
        }
    }

    .nn2 {
        background-color: transparent;
        border: none;
        color: $white;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
            margin-right: 20px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: $blue;
            color: $dark;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .pp2 {
        @extend .nn2;
        i {
            margin-left: 20px;
        }
    }
}
