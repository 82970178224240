.sidebar {
    padding: 40px 0 50px 50px;
    @include sm {
        padding: 30px 0;
    }
    h3 {
        color: $blue;
        font-size: 26px;
    }
    h5 {
        font-size: 16px;
        margin: 20px 0;
        // color: $grey;
    }
}
