#terms {
    .content {
        .term {
            padding: 50px 0;
            .title {
                h3 {
                    // font-size: 30px;
                    &::after {
                        content: " | ";
                        width: 10px;
                        height: 10px;
                        background-color: $blue;
                        color: $blue;
                        margin-left: 20px;
                    }
                }
            }
        }
        hr {
            background-color: $grey;
        }
    }
}
