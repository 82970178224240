.news {
    margin-top: 50px;
    @include sm {
        margin-top: 0px;
    }
    .title {
        text-transform: uppercase;
        font-size: 26px;
    }
    .news-item {
        // min-height: 450px !important;
        // max-height: 450px !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include sm {
            display: block !important;
            min-height: none !important;
            max-height: none !important;
        }
        .news-details-text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
    }

    .sidebar-news {
        // border-right: 1px solid $grey;
        img {
            width: 100%;
            height: auto;
            min-height: 250px;
        }
        @include sm {
            border-right: 0;
        }
        .news-author {
            display: flex;
            // border-top: 1px solid $grey;
            padding-bottom: 50px !important;
            .avater {
                width: 50px;
                height: 50px;
                margin-right: 20px;
                img {
                    width: 100%;
                    border-radius: 50%;
                    height: auto;
                    min-height: auto;
                }
            }
        }
    }
}

.news-page {
    margin-top: 20px !important;
    @include sm {
        margin-top: 20px !important;
    }
}

#new-projects .projects {
    padding-top: 0 !important;
}
#news {
    padding-top: 50px;
    .container {
        max-width: 1200px;
    }
    #new-projects {
        .react-tabs__tab--selected {
            p {
                font-family: "Oswald", sans-serif !important;
            }
            color: $white;
            font-size: 14px;
            border: none !important;
            position: relative !important;
            &::before {
                display: none;
            }
            &:focus {
                &::after {
                    display: none;
                }
            }
            &:nth-child(1) ~ .diamond {
                top: -8%;
            }
            &:nth-child(2) ~ .diamond {
                top: 12%;
                background: $blue;
            }
            &:nth-child(3) ~ .diamond {
                top: 32%;
                background: $blue;
            }
            &:nth-child(4) ~ .diamond {
                top: 52%;
                background: $blue;
            }
            &:nth-child(5) ~ .diamond {
                top: 72%;
                background: $blue;
            }
            @include sm {
                &:nth-child(1) ~ .diamond {
                    top: 0%;
                    left: 2%;
                    margin-left: 0%;
                }
                &:nth-child(2) ~ .diamond {
                    top: -5%;
                    left: 50% !important;
                    background: $blue;
                }
                &:nth-child(3) ~ .diamond {
                    top: -5%;
                    left: 15%;
                    background: $blue;
                }
                &:nth-child(4) ~ .diamond {
                    top: -5%;
                    left: 63%;
                    background: $blue;
                }
                &:nth-child(5) ~ .diamond {
                    top: -5%;
                    left: 63%;
                    background: $blue;
                }
                margin: 0;
            }
        }
    }
}
