.react-tabs__tab {
    color: $grey;
    transition: 0.3s all;
    border: none;
    margin: 10px 0;
    &:focus {
        &::after {
            display: none;
            border: none;
        }
    }
}
.react-tabs__tab--selected {
    color: $white;
    font-size: 16px;
    margin: 10px 0;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    &::before {
        content: "";
        margin-right: 10px;
        color: $blue;
        width: 3px;
        height: 20px;
        background-color: $blue;
    }
    &:focus {
        &::after {
            display: none;
        }
    }
}
.time-line {
    display: flex;
    flex-direction: column;
}

@keyframes move {
    0% {
        transform: translateY(-10);
    }
    100% {
        transform: translateY(0);
    }
}

.time-line {
    position: relative;
    @include sm {
        display: flex;
        justify-content: space-around;
        align-items: center;
        overflow-y: hidden !important;
    }
    .react-tabs__tab {
        font-size: 12px;
        font-weight: 400;
        margin: 5px 0;
        @include sm {
            margin: 20px 0;
        }
    }

    .diamond {
        position: absolute !important;
        top: 0%;
        left: 0%;
        width: 120px;
        height: 80px;
        clip-path: polygon(0 30%, 80% 30%, 100% 50%, 80% 70%, 0 70%);
        background-color: $blue;
        z-index: -1;
        border: none !important;
        margin-left: 25px;
        transition: 0.3s all;
        @include sm {
            width: 50px;
            display: none;
        }
    }

    @include sm {
        display: flex !important;
        flex-direction: row !important;
    }
}

.project-tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $black !important;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.react-tabs__tab-panel--selected {
    animation-name: fadeIn;
    animation-duration: 800ms;
    animation-timing-function: ease-in-out;
    p {
        font-size: 13px;
    }
}

#new-projects {
    .react-tabs__tab--selected {
        &:nth-child(1) ~ .diamond,
        &:nth-child(2) ~ .diamond,
        &:nth-child(3) ~ .diamond {
            @include xl {
                left: 15% !important;
            }
        }
    }
}
#news {
    .react-tabs__tab--selected {
        &:nth-child(1) ~ .diamond,
        &:nth-child(2) ~ .diamond,
        &:nth-child(3) ~ .diamond {
            left: 0% !important;
        }
    }
}
