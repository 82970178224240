footer {
    padding: 20px 0;
    overflow: hidden;
    width: 100%;
    @include sm {
        margin-top: 10px;
        padding: 10px 0;
    }
    @include xl {
        margin-top: 50px;
    }
    img {
        width: 100%;
        height: auto;
    }
    .contact {
        margin: 10px 0;
        display: flex;
        // justify-content: space-between;

        @include sm {
            margin-bottom: 10px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
        .phone {
            margin: 10px;
            margin-left: 0;
            a {
                // margin-bottom: 10px;
                font-size: 13px;
                transition: 0.3s all;

                i {
                    // background-color: #fff;
                    color: $blue;
                    margin-right: 5px !important;
                }
            }
        }
        .mail {
            @extend .phone;
            // margin-left: 20px;
        }
    }

    .location {
        margin: 30px 0;
        // padding: 0 50px;
        width: 350px;
        p {
            font-size: 13px;
            font-weight: 400;
        }
        h5 {
            font-family: "Oswald", sans-serif;
            font-size: 16px;
            text-transform: uppercase;
            // font-weight: 600;
        }
    }
    .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include sm {
            justify-content: center;
            flex-direction: column;
        }
        .copyright {
            font-size: 14px;
            @include sm {
                margin-top: 20px !important;
                text-align: center;
            }
            .logo {
                width: 150px;
                img {
                    margin-left: -10px;
                    width: 100%;
                    height: auto;
                }
            }
            @include sm {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-top: 50px;
            }
            p {
                @include sm {
                    margin-top: 0 !important;
                }
            }
        }
        .menu {
            display: flex;
            flex-direction: column;
            a {
                &:hover {
                    color: $black !important;
                }
            }
            p {
                font-family: "Oswald", sans-serif;
                text-transform: uppercase;
                transition: 0.3s all;
                margin: 5px 0;
                font-size: 14px;
                &:hover {
                    color: $black;
                }
                i {
                    margin-right: 10px;
                }
            }
        }
    }
}
