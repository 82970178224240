.works {
    margin-top: 50px;

    .services {
        margin-top: 50px;
        @include sm {
            margin-top: 20px;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    .service {
        display: flex;
        justify-content: start;
        align-items: center;
        @include sm {
            flex-direction: column;
            justify-content: center;
            margin-top: 20px;
        }

        .info {
            padding: 50px;
            position: absolute;
            @include sm {
                min-width: 100%;
            }
            h5 {
                font-size: 14px;
                color: $white;
            }
            p {
                color: $white;
                font-size: 13px;
            }
            a {
                display: flex;
                justify-content: start;
                align-items: center;
                color: $white;
                i {
                    margin-left: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: $blue;
                    color: $white;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                }
            }
        }
    }
}

.mt-20 {
    margin-top: 200px;
}

.application-form {
    // margin-top: 100px;
    background-color: $light-grey;
    padding: 90px;
    @include sm {
        margin-top: 20px;
        padding: 50px 30px;
        width: 100%;
    }
    select {
        border: none;
        background-color: transparent;
        outline: none;
        border: none;
        // border-bottom: 1px solid $grey;
        padding: 20px;
        background-color: transparent;
        color: $black;
        width: 100%;
        margin-top: 20px;
        color: $grey;
    }
    form {
        margin-top: 50px;

        input,
        textarea {
            border: none;
            // border-bottom: 1px solid $grey;
            padding: 20px;
            background-color: transparent;
            color: $black;
            width: 100%;
            margin-top: 20px;
            &:focus {
                outline: none;
            }
        }
        textarea {
            margin-top: 15px;
            max-height: 65px;
        }
        .input-box {
            border-bottom: 1px solid $grey;
            position: relative;
            input ~ .border,
            textarea ~ .border {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 1px;
            }

            input:focus ~ .border,
            textarea:focus ~ .border {
                width: 100%;
                transition: 0.5s;
                outline: none;
            }
        }
        textarea {
            // min-height: 230px;
            border-bottom: 1px solid $grey;
        }
        .cv {
            display: flex;
            justify-content: space-between;
            padding: 20px;
            margin-top: 12px;
            border-bottom: 1px solid $grey;
        }
        button {
            float: right;
            margin-top: 20px;
        }
    }
}

#career {
    @include sm {
        padding-top: 20px;
    }
    .service-wrapper {
        // padding: 50px 0;
        padding: 0 10px;
        padding: 20px 0;
        padding-bottom: 0;
        .service {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: $light-grey;
            padding: 20px 10px;
            i {
                font-size: 20px;
                // padding: 20px 0;
            }
        }
    }
}
