.floating-social-wrapper {
    position: fixed;
    top: 0%;
    right: 1%;
    z-index: 2000;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .floating-social {
        .social-item {
            margin: 5px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            background-color: $light-grey;
            transition: 0.3s all;
            cursor: pointer;
            a {
                transition: 0.3s all;
            }
            &:hover {
                background-color: $blue;
            }
            &:hover a {
                color: $white !important;
                background-color: $blue;
            }
        }
    }
}
