@mixin sm {
    @media (max-width: 599px) {
        @content;
    }
}
@mixin md {
    @media (min-width: 600px) {
        @content;
    }
}

@mixin xl {
    @media (min-width: 1800px) {
        @content;
    }
}
