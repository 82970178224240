#quote {
    .content {
        margin-top: 50px;
        @include sm {
            margin-top: 0px;
        }
    }
    i {
        color: $blue;
        font-size: 20px;
    }
    // h2 {
    //     font-size: 40px;
    // }
    h3 {
        font-size: 26px;
    }
    h5 {
        font-size: 16px;
    }
    p {
        font-size: 13px;
        a {
            color: $white;
        }
    }
    form {
        background-color: $dark;
        padding: 100px 50px;
        input,
        textarea {
            border: none;
            // border-bottom: 1px solid $grey;
            padding: 20px;
            background-color: transparent;
            color: $white;
            width: 100%;
            margin-top: 20px;
            &:focus {
                outline: none;
            }
        }

        .input-box {
            border-bottom: 1px solid $grey;
            position: relative;
            input ~ .border,
            textarea ~ .border {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 1px;
            }

            input:focus ~ .border,
            textarea:focus ~ .border {
                width: 100%;
                transition: 0.5s;
                outline: none;
            }
        }
    }
}
