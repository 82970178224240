// main scss file

// ------------------------------------------------

// mixins
@import "./helpers/breakPoints";

// base settings
@import "./base/base";

// layouts
@import "./layout/layouts";

// components
@import "./components/components";

// vendor settings
@import "./vendor/react-tabs";

// pages
@import "./pages/pages";

// ------------------------------------------------
$container-max-widths: (
    lg: 992px,
    xl: 1200px,
);
.fp-watermark {
    display: none !important;
}
