.album-wrapper {
    width: 100%;
    height: 300px;
    // overflow: hidden;
    text-align: center;
    .thumbnail-wrapper {
        margin: 20px 0;
        .album-thumbnail {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 300px;
            overflow: hidden;
            border: 3px solid $black;
            img {
                width: 100%;
                height: auto;
                opacity: 0.3;
            }
        }
    }
}
