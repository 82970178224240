#sustainability {
    padding-top: 50px;
    .sustain {
        min-height: 150px;
        max-height: 150px;
        padding: 20px 30px;
        background-color: $light-grey;
        h3 {
            font-size: 30px;
        }
        p {
            text-align: justify;
        }
    }
}
