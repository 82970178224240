#services {
    .service-wrapper {
        // padding: 50px 0;
        padding: 0 10px;
        .service {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            background-color: $light-grey;
            padding: 20px 40px;
            text-align: center;
            min-width: 200px;
            i {
                font-size: 20px;
                // padding: 20px 0;
            }
        }
    }
    .application-form {
        textarea {
            max-height: 65px;
        }
        select {
            border: none;
            background-color: transparent;
            outline: none;
            border: none;
            // border-bottom: 1px solid $grey;
            padding: 20px;
            background-color: transparent;
            color: $black;
            width: 100%;
            margin-top: 20px;
            color: $grey;
        }
    }
}
