#new-projects {
    padding-top: 100px;
    @include sm {
        padding-top: 50px;
    }
    .projects {
        padding: 100px 0;
        position: relative;
        // overflow: hidden;
        h2 {
            text-align: center;
        }
        h3 {
            color: $black;
        }
        .time-line {
            margin-top: 20px;
            height: auto;
            // overflow-y: scroll;
            color: $grey;
            font-weight: bold;
            font-size: 16px;
            @include sm {
                display: flex;
                flex-direction: row;
                height: auto;
                padding: 0px;
                overflow-x: hidden;
                // margin-top: 20px;
            }
            .react-tabs__tab--selected {
                color: $white;
                font-size: 12px;
                border: none !important;
                position: relative !important;
                &::before {
                    display: none;
                }
                &:focus {
                    &::after {
                        display: none;
                    }
                }
                &:nth-child(1) ~ .diamond {
                    top: -7%;
                    left: -45px;
                    @include xl {
                        left: -20% !important;
                    }
                }
                &:nth-child(2) ~ .diamond {
                    top: 10%;
                    left: -45px;
                    background: $blue;
                    @include xl {
                        left: -20% !important;
                    }
                }
                &:nth-child(3) ~ .diamond {
                    top: 26%;
                    left: -45px;
                    background: $blue;
                    @include xl {
                        left: -20% !important;
                    }
                }
                &:nth-child(4) ~ .diamond {
                    top: 43%;
                    left: -45px;
                    background: $blue;
                    @include xl {
                        left: -20% !important;
                    }
                }
                &:nth-child(5) ~ .diamond {
                    top: 60%;
                    left: -45px;
                    background: $blue;
                    @include xl {
                        left: -20% !important;
                    }
                }
                &:nth-child(6) ~ .diamond {
                    top: 76%;
                    left: -45px;
                    background: $blue;
                    @include xl {
                        left: -20% !important;
                    }
                }
            }
        }
        .project-tabs {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0;

            p {
                margin: 0%;
            }
            h5 {
                margin: 0 20px;
                @include sm {
                    text-align: center !important;
                    // display: inline-block;
                }
            }
        }
        .active {
            color: $white;
            // font-weight: bold;
            font-size: 12px;
            margin: 10px 0;
            &::before {
                content: "|";
                margin-right: 10px;
                color: $blue;
            }
        }

        .all-projects {
            @include sm {
                margin-top: 0px !important;
            }

            @include sm {
                margin-top: 100px;
            }
            .title {
                font-size: 18px;
                font-weight: bold;
            }
            .project {
                // margin-bottom: 30px;
                cursor: pointer;
                background-color: $light-grey;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;
                overflow: hidden;

                @include sm {
                    max-height: none;
                }

                .pro-img {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .info {
                    background-color: rgba($color: #000, $alpha: 0.5);
                    position: absolute;
                    bottom: 0%;
                    text-align: justify;
                    padding: 0 20px;
                    height: 50px;
                    color: $white;
                    transition: 0.3s all;
                    h5 {
                        font-size: 16px;
                        margin-top: 20px;
                        text-transform: uppercase;
                    }
                    p {
                        opacity: 0;
                    }
                }

                &:hover .info {
                    height: 150px;
                }
                &:hover .info p {
                    opacity: 1;
                }
                .view {
                    padding: 0 20px;
                    padding-bottom: 20px;
                    height: auto;
                    a.btn-custom {
                        color: $dark;
                        text-align: center;
                    }
                }
            }
        }
    }
    @media only screen and (min-width: 1200px) {
        .container-fluid {
            padding: 0 60px !important;
            @include xl {
                padding: 0 125px !important;
            }
        }
    }
}
