.testimonial {
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // padding: 50px 0;
    @include sm {
        margin-top: 0px;
    }

    .testis {
        .review {
            // padding: 30px;
            padding: 10px;
            width: 100%;
            min-height: 150px;
            overflow: hidden;
            position: relative;
            display: flex;
            justify-content: start;
            align-items: center;
            // text-align: center;
            opacity: 1;
            // cursor: pointer;
            background-color: $light-grey;
            @include xl {
                min-height: 195px;
                padding: 10px 20px;
                width: 100%;
            }
            h3 {
                font-size: 16px;
                color: $blue;
                padding: 0;
                margin: 0;
            }
            p {
                font-size: 11px;
                color: $black;
                text-align: justify;
                padding: 0;
                margin: 0;
                margin-bottom: 11px;
            }
            img {
                width: 100%;
                height: auto;
                transition: 0.3s all;
                max-width: 150px;
                // max-width: 250px;
                @include xl {
                    min-width: 140px;
                }
            }
            .user {
                // position: absolute;
                // z-index: 10;
                padding-left: 20px;
                transition: 0.3s all;
            }
        }
        .about-review {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            // min-height: 940px;
            min-height: 910px;
            @include xl {
                min-height: 596px;
            }
            padding: 45px;
            background-color: $light-grey;
            p {
                text-align: center;
                font-size: 11px;
            }
            h3 {
                font-weight: bold;
            }
        }
    }
}
.mobile-Testimonials {
    display: none;
    @include sm {
        display: block;
    }
    .review {
        margin: 0 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        transition: all 0.4s;
        &:hover {
            transform: scale(1.5);
            z-index: 30;
            cursor: pointer;
        }
        @include sm {
            margin-top: 10px;
        }
        .review-back {
            width: 100%;
            height: auto;
        }
        .user {
            position: absolute;
            left: auto;
            top: auto;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            h3 {
                margin-left: 20px;
                font-size: 20px;
                @include sm {
                    margin: 0;
                }
            }
            p {
                @include sm {
                    margin: 0;
                    padding-top: 5px;
                }
            }
            img {
                width: 40px;
                height: 40px;
            }
            @include sm {
                flex-direction: column;
            }
        }
    }
}
