#loader-wrapper {
    width: 100vw !important;
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0%;
    left: 0%;
    z-index: 900000000;
    background-color: $white;
    img {
        width: 100%;
        height: auto;
    }
}
