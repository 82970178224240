// button {
//     padding: 20px 60px;
//     background-color: transparent;
//     border: none;
//     background-color: $blue;
// }
.btn-custom {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    box-shadow: inset -20px 0 0 0 $blue;
    background-color: $white;
    padding: 15px 50px;
    transition: 0.3s all ease-in;
    font-size: 16px;
    // font-weight: bold;
    border: none;
    &:hover {
        box-shadow: inset 400px 0 0 0 $blue;
        color: $white !important;
    }
}
.more-btn {
    font-family: "Oswald", sans-serif;
    text-transform: uppercase;
    border-bottom: 1px solid $grey;
    font-size: 16px;
    padding: 5px 0;
    color: $grey;
}
