.clients-wrapper {
    padding: 40px 20px;
    @include xl {
        padding: 0px;
        padding-right: 80px;
    }

    .clients {
        padding: 10px;
        width: 100%;
        height: 500px;
        @include xl {
            height: 700px;
            padding: 20px;
        }
        background-color: $light-grey;
        overflow-y: scroll;
        @include sm {
            height: auto;
        }
        .client {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            padding: 10px;
            border-bottom: 1px solid #c1c1c1;
            cursor: pointer;
            transition: 0.3s all;
            @include xl {
                padding: 20px 0;
            }
            @include sm {
                flex-direction: column;
            }
            &:hover {
                background-color: #fff;
            }
            .client-thumb {
                img {
                    width: 80px;
                    height: 80px;
                    // max-width: 100%;
                    // height: auto;
                    @include sm {
                        width: 150px;
                        height: 150px;
                    }
                }
            }
            .client-details {
                padding: 0 20px;
                @include sm {
                    padding: 10px 5px;
                    text-align: center;
                }
                .client-name {
                    // font-weight: 700;
                    // color: $blue;
                    // font-size: 14px;
                    text-transform: uppercase;
                }
                .client-testi {
                    font-size: 13px;
                    color: $black;
                    text-align: justify;
                }
            }
        }
    }
}
