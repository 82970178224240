// header navigation
nav {
    .container-fluid {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 120px;
        padding: 0 60px;
        @include xl {
            padding: 0 125px;
        }
        @include sm {
            padding: 0 20px;
        }
        .brand {
            img {
                width: 100%;
                max-width: 150px;
                @include sm {
                    display: none;
                }
            }
        }
        // .toggle {
        //     img {
        //         width: 100%;
        //         max-width: 80px;
        //     }
        // }
    }
}
