#contact {
    form {
        background-color: $light-grey;
        padding: 100px 50px;
        input,
        textarea {
            border: none;
            // border-bottom: 1px solid $grey;
            padding: 20px;
            background-color: transparent;
            color: $black;
            width: 100%;
            margin-top: 20px;
            &:focus {
                outline: none;
            }
        }

        .input-box {
            border-bottom: 1px solid $grey;
            position: relative;
            input ~ .border,
            textarea ~ .border {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 0;
                height: 1px;
            }

            input:focus ~ .border,
            textarea:focus ~ .border {
                width: 100%;
                transition: 0.5s;
                outline: none;
            }
        }
    }
    .content {
        h5 {
            font-size: 14px;
            font-weight: 700;
        }
        p {
            font-size: 13px;
        }
        .container-fluid {
            padding: 0 60px !important;
            @include xl {
                padding: 0 125px !important;
            }
            @include sm {
                padding: 0 25px !important;
            }
        }
    }
}
