#about {
    .intro {
        width: 100%;
        height: 100vh;
        // background-image: url("../../images/about-banner.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .info {
        background-image: url("../../images/about-info-back.webp");
        margin-top: 100px;
        // background-color: $black;
        padding: 100px 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
    .teams {
        margin-top: 100px;
        .member {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 100%;
                height: auto;
            }
            .member-info {
                position: absolute;
                z-index: 1;
                text-align: center;
                h5 {
                    font-size: 14px;
                }
                p {
                    font-size: 13px;
                }
            }
        }
        .eco-member {
            @extend .member;
            width: 100%;
            height: auto;
            min-height: 200px;
            color: $black;
        }
    }
    .question {
        @extend .info;
        background-image: url("../../images/about-info-back2.webp");
        padding: 120px;
        @include sm {
            padding: 20px;
        }
    }
}
