#faq {
    .content {
        .questions {
            margin-top: 20px;
        }
        @include sm {
            padding: 30px;
        }
        .faq {
            border-bottom: 1px solid $grey;
            padding: 30px 0;
            .qes {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .show-btn {
                    background-color: $black;
                    padding: 10px;
                }
                h5 {
                    font-size: 16px;
                }
            }
            p {
                font-size: 13px;
            }
        }
    }
}
