#social {
    @include sm {
        padding-top: 50px;
    }
    .h-low {
        margin-top: 150px;
    }
    .h-low-2 {
        margin-top: 150px;
        margin-left: -200px;
    }
    h2 {
        @include sm {
            padding-top: 50px;
        }
    }
    .honey {
        margin-top: 50px;
        display: block;
        @include sm {
            display: none;
        }
        .cell-outer {
            display: flex;
            justify-content: center;
            padding: 5px;

            .cell {
                // clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
                background: $black;
                // width: 170px;
                // height: 180px;
                width: 180px;
                height: 190px;
                // margin: -15px 5px;
                // margin: -15px 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                @include xl {
                    width: 240px;
                    height: 250px;
                    // margin: -25px 10px;
                    margin: 10px;
                }

                img {
                    width: 170px;
                    height: 190px;
                    // width: 100%;
                    // height: auto;
                    @include xl {
                        width: 240px;
                        height: 250px;
                    }
                }
                .info {
                    position: absolute;
                    max-width: 100px;
                    h5 {
                        color: $white;
                        font-size: 14px;
                    }
                    a {
                        display: flex;
                        justify-content: start;
                        align-items: center;
                        color: $white;
                        .icon {
                            margin-left: 10px;
                            color: $blue;
                            font-size: 20px;
                        }
                    }
                }
            }
            .cell-empty {
                background-color: transparent;
                .year {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    h5 {
                        font-size: 26px;
                        font-weight: bold;
                    }
                    .v-line {
                        width: 4px;
                        height: 50px;
                        background-color: $blue;
                    }
                }
            }
            .cell-yellow {
                @extend .cell;
                background-color: #ffcb0c;
            }
            .cell-black {
                @extend .cell;
                background-color: #3d3d3d;
            }
        }
    }
    .honey-mobile {
        @extend .honey;
        display: none;
        @include sm {
            display: block;
        }
    }
    .community-works {
        .work {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .work-img {
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .work-info {
                width: 100%;
                background-color: rgba($color: $black, $alpha: 0.5);
                color: $white;
                padding: 20px;
                padding-bottom: 0;
                position: absolute;
                bottom: 0;
                left: 0;
                transition: 0.3s all;
            }
            &:hover .work-info {
                background-color: rgba($color: $black, $alpha: 1);
            }
        }
    }
    .clients-wrapper {
        padding: 0;
        padding-right: 20px;

        @include sm {
            padding-right: 0;
        }
        @include xl {
            padding-right: 50px;
        }
        .clients {
            height: 550px;
            @include xl {
                height: 700px;
            }
        }
    }
}
