.full-project-view-wrapper {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0%;
    left: 0%;
    z-index: 1000000000;
    background-color: $white;

    .close-btn {
        color: $black;
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 10%;
        right: 5%;
        z-index: 100000000;
        cursor: pointer;
        transition: 0.6s all;
        border: 1px solid $black;
        font-weight: bold;
        box-shadow: inset 0px 0 0 0 $black;
        &:hover {
            // background-color: $blue;
            box-shadow: inset 400px 0 0 0 $black;
            color: $white;
        }
        @include sm {
            top: 10% !important;
            bottom: auto;
        }
    }
    .items {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        img {
            width: auto;
            height: auto;
            // max-width: 100%;
            // max-height: 100%;
        }
    }
    .thumbs {
        bottom: 0% !important;
        left: 0% !important;
        position: absolute !important;
        z-index: 100000;
        background-color: $light-grey;
        width: 100%;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .carousel-status {
        top: 93% !important;
        left: 10% !important;
        z-index: 10000000;
        font-size: 20px !important;
        width: 100px !important;
        height: 100px !important;
        color: $black !important;
        box-shadow: none;
        text-shadow: none !important;
        @include sm {
            top: 10% !important;
        }
    }
}

#home .full-project-view-wrapper {
    position: absolute;
}
#about .thumbs-wrapper {
    position: absolute;
    bottom: 0%;
    left: 0;
    z-index: 10000000000;
    .thumbs {
        overflow-x: scroll;
        .thumb {
            min-width: 100px;
        }
    }
}
.project-info-wrapper {
    background-color: $white;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
    .project-info {
        text-align: justify;
    }
    .project-box {
        background-color: $light-grey;
        padding: 20px;
        margin: 20px 0;
    }
}
