.full-testi-view-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0%;
    left: 0%;
    z-index: 10000000;
    background-color: $light-grey;
    // padding: 0 100px;

    // text select none
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    @include sm {
        min-height: 100vh;
        overflow-y: scroll;
    }

    .close-btn {
        margin-top: 50px;
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.6s all;
        border: 3px solid $black;
        font-weight: bold;
        box-shadow: inset 0px 0 0 0 $black;
        position: absolute;
        z-index: 10000000;
        right: 5%;
        &:hover {
            // background-color: $blue;
            box-shadow: inset 400px 0 0 0 $black;
            color: $white;
        }
        @include sm {
            margin-top: 0px;
            left: 0;
            bottom: 5%;
            position: relative;
        }
    }
    .testimonial {
        padding: 50px 80px;
        @include sm {
            padding: 20px;
            min-height: none;
        }
        .review {
            position: relative;
            @include sm {
                min-height: 100%;
            }
            img {
                width: 100%;
                height: auto;
                // max-width: 400px;
            }
            .review-details {
                // padding: 40px;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 50px;
                text-align: justify;
                @include sm {
                    padding: 20px;
                }
            }
        }
    }
}

#home .full-testi-view-wrapper {
    position: absolute;
}
