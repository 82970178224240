#case-study {
    .cases {
        margin-top: 50px;
        img {
            width: 100%;
            height: auto;
        }
        .row {
            margin: 0;
            padding: 0;
        }
        .col-lg-3 {
            margin: 0;
            padding: 0;
        }
        .case {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .info {
            position: absolute;
            z-index: 1;
            padding: 40px;
            h5 {
                font-size: 16px;
            }
            p {
                font-size: 13px;
            }
            a {
                color: $white;
                display: flex;
                justify-content: start;
                align-items: center;
                i {
                    width: 30px;
                    height: 30px;
                    margin-left: 20px;
                    background-color: $blue;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                }
            }
        }
        .pagination {
            .years {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
