#privicy {
    .content {
        margin-top: 20px;
        h2 {
            margin: 20px 0;
            font-size: 40px;
        }
        h5 {
            font-size: 18px;
        }
        .letter-end {
            margin-top: 50px;
            p {
                margin: 0;
            }
            h3 {
                font-size: 20px;
            }
        }
    }
}
